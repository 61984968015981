import { createSlice } from '@reduxjs/toolkit';
import { IAddNote, INote } from '~src/common/interfaces/notes.interfaces';
import { apiCallBegan } from '~store/middleware/api/api';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

const initialState = {
  loading: false,
  noteItems: [] as INote[],
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    notesRequested: (notes) => {
      notes.loading = true;
    },
    notesReceived: (notes, action) => {
      notes.loading = false;
      notes.noteItems = action.payload;
    },
    notesRequestedFailed: (notes) => {
      notes.loading = false;
    },
    noteCreated: (notes, action) => {
      const { ...newNote } = action.payload;
      notes.noteItems.unshift(newNote);
      notes.loading = false;
    },
    noteUpdated: (notes, action) => {
      const { ...updatedNote } = action.payload;
      const updatedIndex = notes.noteItems.findIndex(
        (note) => note.noteid === updatedNote.noteid,
      );
      notes.noteItems[updatedIndex] = updatedNote;
      notes.noteItems.sort((a, b) => b.updated.localeCompare(a.updated));
      notes.loading = false;
    },
    noteDeleted: (notes, action) => {
      const { ...deletedNote } = action.payload;
      notes.noteItems = notes.noteItems.filter(
        (note) => note.noteid !== deletedNote.noteid,
      );
      notes.loading = false;
    },
  },
});

export const {
  notesRequested,
  notesReceived,
  notesRequestedFailed,
  noteCreated,
  noteUpdated,
  noteDeleted,
} = slice.actions;

export default slice.reducer;

export const loadProjectNotes = (projectid: string) => {
  return apiCallBegan({
    url: `api/notes/${projectid}`,
    method: 'GET',
    onStart: notesRequested.type,
    onSuccess: notesReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.notes_failedToLoadNotes),
        },
      });
      return {
        type: notesRequestedFailed.type,
      };
    },
  });
};

export const createNote = (newNote: IAddNote) =>
  apiCallBegan({
    url: 'api/notes',
    method: 'POST',
    data: newNote,
    onStart: notesRequested.type,
    onSuccess: noteCreated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.notes_failedToCreateNote),
        },
      });
      return {
        type: notesRequestedFailed.type,
      };
    },  });

export const updateNote = ({ noteid, note }: Pick<INote, 'noteid' | 'note'>) => {
  return apiCallBegan({
    url: `api/notes/${noteid}`,
    method: 'PATCH',
    data: { noteid, note },
    onStart: notesRequested.type,
    onSuccess: noteUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.notes_failedToUpdateNote),
        },
      });
      return {
        type: notesRequestedFailed.type,
      };
    },
  });
};

export const deleteNote = (noteid: INote['noteid']) => {
  return apiCallBegan({
    url: `api/notes/${noteid}`,
    method: 'DELETE',
    onStart: notesRequested.type,
    onSuccess: noteDeleted.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.notes_failedToDeleteNote),
        },
      });
      return {
        type: notesRequestedFailed.type,
      };
    },
  });
};
