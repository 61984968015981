import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { costTrackerFields } from '~src/enums/cost-tracker';
import { isValidNum } from '@oliasoft-open-source/units';
import {
  IAddCostTrackerItem,
  ICostTrackerInitial,
} from '~src/common/interfaces/cost-tracker.interfaces';

const initialState: ICostTrackerInitial = {
  isAdding: false,
  isFetching: false,
  list: [],
  start: '',
  finish: '',
  disabledFields: [],
};

const costTracker = createSlice({
  name: 'costTracker',
  initialState,
  reducers: {
    itemsRequested: (costTracker) => {
      costTracker.isFetching = true;
    },
    itemsFetched: (costTracker, action) => {
      costTracker.isFetching = false;
      costTracker.list = action.payload.costTrackerItems;
      costTracker.start = action.payload.start;
      costTracker.finish = action.payload.finish;
    },
    itemsRequestedFailed: (costTracker) => {
      costTracker.isFetching = false;
    },
    addTaskRequested: (costTracker) => {
      costTracker.isAdding = true;
    },
    taskAdded: (costTracker, action) => {
      const { costTrackerItems } = action.payload;
      costTracker.list = costTrackerItems;
      costTracker.isAdding = false;
    },
    taskRemoved: (costTracker, action) => {
      const { costTrackerItems } = action.payload;
      costTracker.list = costTrackerItems;
      costTracker.isAdding = false;
    },
    taskReodered: (costTracker, action) => {
      const { costTrackerItems } = action.payload;
      costTracker.list = costTrackerItems;
      costTracker.isAdding = false;
    },
    updateTaskRequested: (costTracker) => {
      costTracker.isAdding = true;
    },
    taskUpdated: (costTracker) => {
      costTracker.isAdding = false;
    },
    listCleared: (costTracker) => {
      costTracker.list = [];
      costTracker.start = '';
      costTracker.finish = '';
    },
    cellValueUpdated: (costTracker, action) => {
      const { sectionId, costTrackerItemId, value, field } = action.payload;
      const costTrackerSectionIdx = costTracker.list.findIndex(
        (section) => section.sectionId === sectionId,
      );
      const costTrackerItemIndex = costTracker.list[
        costTrackerSectionIdx
      ].operations.findIndex(
        (item) => item.costTrackerItemId === costTrackerItemId,
      );
      const updatedOperation = {...costTracker.list[costTrackerSectionIdx].operations[costTrackerItemIndex], [field]: value};
      costTracker.list[costTrackerSectionIdx].operations[costTrackerItemIndex] = updatedOperation;
      if (field === costTrackerFields.COST_ACTUAL) {
        const costAfe =
          costTracker.list[costTrackerSectionIdx].operations[
            costTrackerItemIndex
          ][costTrackerFields.COST_AFE];
        // update variance if costActual has been changed
        if (isValidNum(value) && isValidNum(costAfe)) {
          costTracker.list[costTrackerSectionIdx].operations[
            costTrackerItemIndex] = {...updatedOperation, [costTrackerFields.VARIANCE]: value - costAfe!};
        }
        // recalculate cumulativeCost if costActual has been changed
        costTracker.list.forEach((section, sectionIdx) => {
          if (sectionIdx >= costTrackerSectionIdx) {
            let prevCumulativeCost =
              sectionIdx > 0
                ? costTracker.list[sectionIdx - 1].operations[
                    costTracker.list[sectionIdx - 1].operations.length - 1
                  ].cumulativeCost
                : 0;
            section.operations.forEach((operation) => {
              const cumulativeCost =
                prevCumulativeCost! +
                (isValidNum(operation?.costActual)
                  ? Number(operation?.costActual)
                  : 0);
              operation.cumulativeCost = cumulativeCost;
              prevCumulativeCost = cumulativeCost;
            });
            const currentOperationList =
              costTracker.list[sectionIdx].operations;
            costTracker.list[sectionIdx].cumulativeCost =
              currentOperationList[
                currentOperationList.length - 1
              ].cumulativeCost;
          }
        });
      }
    },
    disabledFieldsUpdated: (costTracker, action) => {
      costTracker.disabledFields = action.payload;
    },

    updateStartTimeRequested: (costTracker) => {
      costTracker.isAdding = true;
    },
    startTimeUpdated: (costTracker, action) => {
      const { costTrackerItems, start, finish } = action.payload;
      costTracker.list = costTrackerItems;
      costTracker.start = start;
      costTracker.finish = finish;
      costTracker.isAdding = false;
    },
  },
});

export const {
  itemsRequested,
  itemsFetched,
  addTaskRequested,
  taskAdded,
  taskRemoved,
  taskUpdated,
  listCleared,
  updateTaskRequested,
  cellValueUpdated,
  disabledFieldsUpdated,
  updateStartTimeRequested,
  startTimeUpdated,
  itemsRequestedFailed,
  taskReodered,
} = costTracker.actions;
export default costTracker.reducer;

export const getCostTrackerItems = (projectId: string) =>
  apiCallBegan({
    url: `/api/cost-tracker/${projectId}`,
    method: 'GET',
    onStart: itemsRequested.type,
    onSuccess: (response) => ({
      type: itemsFetched.type,
      payload: response,
    }),
    onError: itemsRequestedFailed.type,
  });


export const addCostTrackerItem = (data: IAddCostTrackerItem) =>
  apiCallBegan({
    url: '/api/cost-tracker/operation',
    method: 'POST',
    data,
    onStart: addTaskRequested.type,
    onSuccess: (response) => ({
      type: taskAdded.type,
      payload: response,
    }),
  });


export const deleteCostTrackerItem = (
  projectId: string,
  costTrackerId: string,
  costTrackerItemId: string,
) =>
  apiCallBegan({
    url: `/api/cost-tracker/task/${projectId}`,
    method: 'DELETE',
    data: { costTrackerId, costTrackerItemId },
    onSuccess: (response) => ({
      type: taskRemoved.type,
      payload: response,
    }),
  });


export const updateCostTrackerItem = (costTrackerItemId: string, data: {field: string, value: string | number | null}) =>
  apiCallBegan({
    url: `/api/cost-tracker/operation/${costTrackerItemId}`,
    method: 'PUT',
    data,
    onStart: updateTaskRequested.type,
    onSuccess: (response) => ({
      type: taskUpdated.type,
      payload: response,
    }),
  });


export const updateCostTrackerStart = (projectId: string, startTime: string, costTrackerId: string) =>
  apiCallBegan({
    url: `/api/cost-tracker/start/${projectId}`,
    method: 'PUT',
    data: { startTime, costTrackerId },
    onStart: updateStartTimeRequested.type,
    onSuccess: (response) => ({
      type: startTimeUpdated.type,
      payload: response,
    }),
  });

export const reorderCostTrackerList = (projectId: string, data: { fromItemId: string, toItemId: string }) =>
  apiCallBegan({
    url: `/api/cost-tracker/reorder/${projectId}`,
    method: 'PUT',
    data,
    onStart: updateTaskRequested.type,
    onSuccess: (response) => ({
      type: taskReodered.type,
      payload: response,
    }),
  });
