import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Table,
  Spacer,
  useKeyboardEvent,
  Empty,
  Flex,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { TaskOffsetHeader } from '~src/views/activity-model/tasks/task-offset-header';
import { formatTimeToUI } from '~src/views/time-tracker/utils/date/dateUtils';
import { round } from '@oliasoft-open-source/units';
import { useParams } from 'react-router';
import { routes } from '~routes/routes';
import { generatePath } from '~store/navigation/navigation';
import { updateShowOffsetPreviewModal } from '~src/store/entities/activity-model/activity-model';
import { IOffsetActivities } from '~src/common/interfaces/activity-model';
import type { TRootState } from '~store/store-types';

type TaskOffsetModalProps = PropsFromRedux & {
  task: string;
  operation: string;
  section: string;
  offsetActivities: IOffsetActivities;
};

const TaskOffsetModal = ({
  offsetActivities: { offsetActivities, calculatedTimes },
  task,
  operation,
  section,
  updateShowOffsetPreviewModal,
}: TaskOffsetModalProps) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const onClose = () => updateShowOffsetPreviewModal();
  useKeyboardEvent('Escape', onClose);
  const rows = offsetActivities?.map((activity: any) => {
    return {
      cells: [
        {
          value: activity.name,
          type: 'Link',
          onClick: () =>
            window.open(
              generatePath(routes.timeTracker.fullPath, {
                company,
                project: activity.projectId,
              }),
              '_blank',
            ),
        },
        {
          value: formatTimeToUI(activity.start),
        },
        {
          value: activity.afe,
        },
        {
          value: activity.estimate,
        },
        {
          value: activity.actual,
          style: {
            background: 'var(--color-background)',
          },
        },
        {
          value: round(activity.estimate - activity.actual),
        },
        {
          value: formatTimeToUI(activity.finish),
        },
        {
          value: activity.plannedDepth,
        },
        {
          value: activity.actualDepth,
        },
      ],
    };
  });
  const table = {
    fixedWidth: 'auto',
    headers: [
      {
        cells: [
          {
            value: t(translations.activityModel_offsetWell),
          },
          {
            value: t(translations.timeTracker_startTime),
          },
          {
            value: t(translations.timeTracker_afe),
          },
          {
            value: t(translations.timeTracker_estimate),
          },
          {
            value: t(translations.actual),
          },
          {
            value: t(translations.timeTracker_variance),
          },
          {
            value: t(translations.timeTracker_finishTime),
          },
          {
            value: t(translations.timeTracker_planned),
          },
          {
            value: t(translations.timeTracker_actualDepth),
          },
        ],
      },
      {
        cells: [
          {
            value: '',
            colSpan: 2,
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'h',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: '',
          },
          {
            value: 'm',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
          {
            value: 'm',
            style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
          },
        ],
      },
    ],
    rows,
  };
  const content =
    offsetActivities &&
    offsetActivities.length > 0 &&
    calculatedTimes?.distribution ? (
      <>
        <TaskOffsetHeader
          calculatedTimes={calculatedTimes}
          task={task}
          operation={operation}
          section={section}
        />
        <Spacer />
        <Table table={table} />
      </>
    ) : (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Empty
          height="100%"
          text={t(translations.activityModel_noResultsFound)}
          width="100%"
        ></Empty>
      </Flex>
    );
  const footer = (
    <>
      <Button label={t(translations.close)} onClick={onClose} colored />
    </>
  );
  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.activityModel_previewTimeFromOffsetWells),
          content,
          footer,
          onClose,
        }}
      />
    </Modal>
  );
};
const mapStateToProps = ({ entities }: TRootState) => {
  const {
    activityModel: { offsetActivities },
  } = entities;

  return { offsetActivities };
};

const mapDispatchToProps = { updateShowOffsetPreviewModal };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(TaskOffsetModal), {
  isModal: true,
});

export { Container as TaskOffsetModal };
