import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OptionDropdown, Icon } from '@oliasoft-open-source/react-ui-library';
import { disabledFieldsUpdated } from '~store/entities/cost-tracker/cost-tracker';
import { costTrackerFields } from '~src/enums/cost-tracker';
import translations from '~src/internationalisation/translation-map.json';

const EditColumns = ({ disabledFieldsUpdated, labelConfig = {} }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([
    {
      label: t(translations.timeTracker_startTime),
      value: costTrackerFields.START,
      selected: true,
    },
    {
      label:
        labelConfig?.[costTrackerFields.TIME_AFE] ||
        t(translations.costTracker_timeAfe),
      value: costTrackerFields.TIME_AFE,
      selected: true,
    },
    {
      label:
        labelConfig?.[costTrackerFields.COST_AFE] ||
        t(translations.costTracker_costAfe),
      value: costTrackerFields.COST_AFE,
      selected: true,
    },
    {
      label: t(translations.costTracker_timeActual),
      value: costTrackerFields.TIME_ACTUAL,
      selected: true,
    },
    {
      label: t(translations.costTracker_costActual),
      value: costTrackerFields.COST_ACTUAL,
      selected: true,
    },
    {
      label: t(translations.timeTracker_variance),
      value: costTrackerFields.VARIANCE,
      selected: true,
    },
    {
      label: t(translations.costTracker_cumulativeCost),
      value: costTrackerFields.CUMULATIVE_COST,
      selected: true,
    },
  ]);

  const icon = <Icon icon="settings" size="16px" />;
  return (
    <OptionDropdown
      onChange={(e) => {
        setOptions(e.target.value);
        const disabledFields = e.target.value
          .filter((item) => item.selected === false)
          .map((item) => item.value);
        disabledFieldsUpdated(disabledFields);
      }}
      options={options}
      label={icon}
      showHeader={false}
    />
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding } = entities.costTracker;
  const labelConfig = {};
  return { isAdding, labelConfig };
};

const mapDispatchToProps = { disabledFieldsUpdated };
const Container = connect(mapStateToProps, mapDispatchToProps)(EditColumns);

export { Container as EditColumns };
