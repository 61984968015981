import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  Divider,
  Field,
  InputGroup,
  Loader,
  Modal,
  RadioButton,
  readFile,
  Spacer,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { Input, Select } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { projectsSchemaValidator } from '~schemas/ajv-validators';
import { CreateEstimateOptions } from '~src/enums/projects';
import { generatePath, navigateToPath } from '~store/navigation/navigation';
import { routes } from '~routes/routes';
import { FileInput } from '~common/form-inputs/file-input';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import {
  ICountry,
  IDesign,
  IField,
  ISite,
  IWell,
  IWellbore,
} from '~common/interfaces/hierarchy.interfaces.ts';
import { HierarchyLevelType } from '~src/enums/hierarchy.ts';
import { createHierarchyWithProject } from '~store/entities/hierarchy/hierarchy.ts';
import type { TRootState } from '~store/store-types';

type CreateModalProps = PropsFromRedux & {
  companyId: string;
  setModalVisible: (visible: boolean) => void;
  countriesOptions: { label: string; value: string }[];
  labelWidth?: string;
  activeCountry: ICountry | null;
  activeField: IField | null;
  activeSite: ISite | null;
  activeWell: IWell | null;
  activeWellbore: IWellbore | null;
  activeDesign: IDesign | null;
};

const CreateModal = ({
  companyId,
  setModalVisible,
  countriesOptions,
  list,
  isAdding,
  navigateToPath,
  activeCountry,
  activeField,
  activeSite,
  activeWell,
  activeWellbore,
  activeDesign,
  createHierarchyWithProject,
}: CreateModalProps) => {
  const { t } = useTranslation();
  const labelWidth = 50;
  const inputWidth = 495;
  const selectWidth = 460;
  const createOptions = [
    { label: t(translations.createNew), value: CreateEstimateOptions.New },
    { label: t(translations.copyExisting), value: CreateEstimateOptions.Copy },
    { label: t(translations.fileImport), value: CreateEstimateOptions.Import },
  ];
  const [createOptionsValue, setCreateOptionsValue] = useState<{
    label?: string;
    value: CreateEstimateOptions;
  }>(createOptions[0]);
  const createOptionsOnChange = (e: any) => {
    const { value } = e.target;
    setCreateOptionsValue({ value });
  };
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    trigger,
    formState: { errors },
    formState,
  } = useForm({
    defaultValues: {
      projectData: null,
      country: null,
      existingCountry: null,
      field: '',
      existingField: null,
      site: '',
      existingSite: null,
      well: '',
      existingWell: null,
      wellbore: '',
      existingWellbore: null,
      design: '',
      existingDesign: null,
      estimate: '',
      existingEstimate: null,
    },
    resolver: getResolver(projectsSchemaValidator),
    mode: 'onChange',
  });

  const [showCountry, setShowCountry] = useState(false);
  const [showField, setShowField] = useState(false);
  const [showSite, setShowSite] = useState(false);
  const [showWell, setShowWell] = useState(false);
  const [showWellbore, setShowWellbore] = useState(false);
  const [showDesign, setShowDesign] = useState(false);
  const [file, setFile] = useState(null);

  const clearFields = (level: HierarchyLevelType) => {
    const clearMap: {
      [HierarchyLevelType.Country]: string[];
      [HierarchyLevelType.Field]: string[];
      [HierarchyLevelType.Site]: string[];
      [HierarchyLevelType.Well]: string[];
      [HierarchyLevelType.Wellbore]: string[];
      [HierarchyLevelType.Design]?: string[];
      [HierarchyLevelType.Estimate]?: string[];
    } = {
      [HierarchyLevelType.Country]: [
        'field',
        'existingField',
        'site',
        'existingSite',
        'well',
        'existingWell',
        'wellbore',
        'existingWellbore',
        'design',
        'existingDesign',
      ],
      [HierarchyLevelType.Field]: [
        'site',
        'existingSite',
        'well',
        'existingWell',
        'wellbore',
        'existingWellbore',
        'design',
        'existingDesign',
      ],
      [HierarchyLevelType.Site]: [
        'well',
        'existingWell',
        'wellbore',
        'existingWellbore',
        'design',
        'existingDesign',
      ],
      [HierarchyLevelType.Well]: [
        'wellbore',
        'existingWellbore',
        'design',
        'existingDesign',
      ],
      [HierarchyLevelType.Wellbore]: ['design', 'existingDesign'],
    };

    const fieldsToClear = clearMap[level];

    fieldsToClear?.forEach((field) =>
      setValue(field, field.startsWith('existing') ? null : ''),
    );
  };

  useEffect(() => {
    const estimate = list.estimate.data.find((item) => item.active);
    if (estimate) {
      navigateToPath(
        generatePath(
          routes.overview,
          {
            company: companyId,
            project: estimate.estimateid,
          },
          true,
        ),
      );
    }
  }, [list.estimate]);

  useEffect(() => {
    if (formState.errors.field) {
      setShowField(true);
    }
    if (formState.errors.site) {
      setShowSite(true);
    }
    if (formState.errors.well) {
      setShowWell(true);
    }
    if (formState.errors.wellbore) {
      setShowWellbore(true);
    }
    if (formState.errors.design) {
      setShowDesign(true);
    }
  }, [formState]);

  const projectData = watch('projectData');
  // Preselect hierarchy on import from file
  useEffect(() => {
    if (
      createOptionsValue.value === CreateEstimateOptions.Import &&
      projectData
    ) {
      clearFields(HierarchyLevelType.Country);
      if (
        projectData?.hierarchy &&
        list.country.data.find(
          (country) => country.countryid === projectData.hierarchy.countryid,
        )
      ) {
        const { countryid, fieldid, siteid, wellid, wellboreid, designid } =
          projectData.hierarchy;
        setValue('existingCountry', countryid);
        setValue('existingField', fieldid);
        setValue('existingSite', siteid);
        setValue('existingWell', wellid);
        setValue('existingWellbore', wellboreid);
        setValue('existingDesign', designid);
      } else if (
        list.country.data.find(
          (country) => country.countryid === activeCountry?.countryid,
        )
      ) {
        setValue('existingCountry', activeCountry?.countryid);
        if (activeField) {
          setValue('existingField', activeField.fieldid);
        }
        if (activeSite) {
          setValue('existingSite', activeSite.siteid);
        }
        if (activeWell) {
          setValue('existingWell', activeWell.wellid);
        }
        if (activeWellbore) {
          setValue('existingWellbore', activeWellbore.wellboreid);
        }
        if (activeDesign) {
          setValue('existingDesign', activeDesign.designid);
        }
      }
    }
  }, [projectData, createOptionsValue]);

  const onSubmit = handleSubmit((data) => {
    delete data.projectFile;
    createHierarchyWithProject(data);
  });
  const onClose = () => setModalVisible(false);
  const onImportFile = async (e: any) => {
    const file = e.target.value;
    if (!file) {
      return;
    }

    try {
      const text = await readFile(file);
      const json = JSON.parse(text);

      setFile(file);
      setValue('projectData', json);
    } catch (error) {
      setError('projectFile', { type: 'custom', message: 'Invalid file' });
    }
  };

  const country = watch('country');
  const existingCountry = watch('existingCountry');
  const countryNotSelected = !country && !existingCountry;
  const field = watch('field');
  const existingField = watch('existingField');
  const fieldNotSelected = !field && !existingField;
  const site = watch('site');
  const existingSite = watch('existingSite');
  const siteNotSelected = !site && !existingSite;
  const well = watch('well');
  const existingWell = watch('existingWell');
  const wellNotSelected = !well && !existingWell;
  const wellbore = watch('wellbore');
  const existingWellbore = watch('existingWellbore');
  const wellboreNotSelected = !wellbore && !existingWellbore;

  const existingCountriesOptions = list.country.data.map((country) => ({
    label: country.name,
    value: country.countryid,
  }));
  const existingFieldsOptions = list.field.data
    .filter((item) => item.countryid === existingCountry)
    .map((item) => ({ label: item.name, value: item.fieldid }));
  const existingSitesOptions = list.site.data
    .filter((item) => item.fieldid === existingField)
    .map((item) => ({ label: item.name, value: item.siteid }));
  const existingWellsOptions = list.well.data
    .filter((item) => item.siteid === existingSite)
    .map((item) => ({ label: item.name, value: item.wellid }));
  const existingWellboresOptions = list.wellbore.data
    .filter((item) => item.wellid === existingWell)
    .map((item) => ({ label: item.name, value: item.wellboreid }));
  const existingDesignsOptions = list.design.data
    .filter((item) => item.wellboreid === existingWellbore)
    .map((item) => ({ label: item.name, value: item.designid }));
  const existingEstimateOptions = list.estimate.data.map((estimate) => ({
    label: estimate.name,
    value: estimate.estimateid,
  }));

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.projects_createEstimate),
          width: '600px',
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isAdding && (
                <Loader text={t(translations.adding)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <RadioButton
                  options={createOptions}
                  value={createOptionsValue}
                  onChange={createOptionsOnChange}
                  inline
                />
                <Divider />
                {createOptionsValue.value === CreateEstimateOptions.Import && (
                  <Field
                    label={t(translations.fileToImport)}
                    labelLeft
                    labelWidth={labelWidth}
                  >
                    <FileInput
                      name="projectFile"
                      file={file}
                      control={control}
                      errors={errors}
                      onChange={onImportFile}
                    />
                  </Field>
                )}
                {createOptionsValue.value === CreateEstimateOptions.Copy && (
                  <>
                    <Field
                      label={t(translations.projects_copyExisting)}
                      labelLeft
                      labelWidth={labelWidth}
                    >
                      <Select
                        options={existingEstimateOptions}
                        name="existingEstimate"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                      />
                    </Field>
                  </>
                )}
                <Field
                  label={t(translations.country)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingCountriesOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingCountriesOptions}
                        name="existingCountry"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        onChange={() => {
                          setShowCountry(false);
                          setValue('country', null);
                          clearFields(HierarchyLevelType.Country);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowCountry(true);
                          setValue('existingCountry', null);
                          clearFields(HierarchyLevelType.Country);
                        }}
                        icon="add"
                      />
                    </InputGroup>
                  )}
                  {(existingCountriesOptions.length === 0 || showCountry) && (
                    <>
                      <Spacer height={5} />
                      <Select
                        options={countriesOptions}
                        name="country"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        onChange={() => {
                          setValue('existingCountry', null);
                          trigger(['existingCountry']);
                        }}
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.field)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingFieldsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingFieldsOptions}
                        name="existingField"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={countryNotSelected}
                        onChange={async () => {
                          setValue('field', null);
                          await trigger(['field']);
                          setShowField(false);
                          clearFields(HierarchyLevelType.Field);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowField(true);
                          setValue('existingField', null);
                          clearFields(HierarchyLevelType.Field);
                        }}
                        icon="add"
                        disabled={countryNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showField || existingFieldsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="field"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={countryNotSelected}
                        onChange={() => {
                          setValue('existingField', null);
                        }}
                        placeholder={
                          countryNotSelected
                            ? t(translations.projects_selectCountryFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.site)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingSitesOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingSitesOptions}
                        name="existingSite"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={fieldNotSelected}
                        onChange={async () => {
                          setValue('site', null);
                          await trigger(['site']);
                          setShowSite(false);
                          clearFields(HierarchyLevelType.Site);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowSite(true);
                          setValue('existingSite', null);
                          clearFields(HierarchyLevelType.Site);
                        }}
                        icon="add"
                        disabled={fieldNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showSite || existingSitesOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="site"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={fieldNotSelected}
                        onChange={() => setValue('existingSite', null)}
                        placeholder={
                          fieldNotSelected
                            ? t(translations.projects_selectFieldFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.well)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingWellsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingWellsOptions}
                        name="existingWell"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={siteNotSelected}
                        onChange={async () => {
                          setValue('well', null);
                          await trigger(['well']);
                          setShowWell(false);
                          clearFields(HierarchyLevelType.Well);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowWell(true);
                          setValue('existingWell', null);
                          clearFields(HierarchyLevelType.Well);
                        }}
                        icon="add"
                        disabled={siteNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showWell || existingWellsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="well"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={siteNotSelected}
                        onChange={() => setValue('existingWell', null)}
                        placeholder={
                          siteNotSelected
                            ? t(translations.projects_selectSiteFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.wellbore)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingWellboresOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingWellboresOptions}
                        name="existingWellbore"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={wellNotSelected}
                        onChange={async () => {
                          setValue('wellbore', null);
                          await trigger(['wellbore']);
                          setShowWellbore(false);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowWellbore(true);
                          setValue('existingWellbore', null);
                        }}
                        icon="add"
                        disabled={wellNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showWellbore || existingWellboresOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="wellbore"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={wellNotSelected}
                        onChange={() => setValue('existingWellbore', null)}
                        placeholder={
                          wellNotSelected
                            ? t(translations.projects_selectWellFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.design)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  {existingDesignsOptions.length !== 0 && (
                    <InputGroup>
                      <Select
                        options={existingDesignsOptions}
                        name="existingDesign"
                        control={control}
                        errors={errors}
                        width={selectWidth}
                        disabled={wellboreNotSelected}
                        onChange={async () => {
                          setValue('design', null);
                          await trigger(['design']);
                          setShowDesign(false);
                        }}
                      />
                      <Button
                        name="add"
                        onClick={() => {
                          setShowDesign(true);
                          setValue('existingDesign', null);
                        }}
                        icon="add"
                        disabled={wellboreNotSelected}
                      />
                    </InputGroup>
                  )}
                  {(showDesign || existingDesignsOptions.length === 0) && (
                    <>
                      <Spacer height={5} />
                      <Input
                        name="design"
                        control={control}
                        errors={errors}
                        width={inputWidth}
                        disabled={wellboreNotSelected}
                        onChange={() => setValue('existingDesign', null)}
                        placeholder={
                          wellboreNotSelected
                            ? t(translations.projects_selectWellboreFirst)
                            : ''
                        }
                      />
                    </>
                  )}
                </Field>
                <Field
                  label={t(translations.estimate)}
                  labelLeft
                  labelWidth={labelWidth}
                >
                  <Input
                    name="estimate"
                    control={control}
                    errors={errors}
                    width={inputWidth}
                  />
                </Field>
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { list, isAdding } = entities.hierarchy;

  return { list, isAdding };
};

const mapDispatchToProps = { navigateToPath, createHierarchyWithProject };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CreateModal), { isModal: true });

export { Container as CreateModal };
