import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { debounce } from 'lodash';
import { Table, Heading, Flex } from '@oliasoft-open-source/react-ui-library';
import {
  cellValueUpdated,
  updateCostTrackerItem,
  addCostTrackerItem,
  deleteCostTrackerItem,
  reorderCostTrackerList,
} from '~store/entities/cost-tracker/cost-tracker';
import translations from '~src/internationalisation/translation-map.json';
import {
  buildHeaders,
  buildCostTrackerItem,
} from '~views/cost-tracker/operations-table-builder';
import { selectDisabledFields } from '~store/entities/cost-tracker/selectors';
import { selectCostItemList } from '~store/entities/cost-setup/selector';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { addTaskOptions } from '~views/time-tracker/utils/consts';
import { getSetionData } from './utils/utils';
import { getColumnAlignment } from './utils/get-ct-column-alignment';
import { getColumnWidth } from './utils/get-ct-column-width';
import { EditColumns } from './edit-columns';

const OperationsTable = ({
  itemsList,
  cellValueUpdated,
  updateCostTrackerItem,
  disabledFields,
  reorderCostTrackerList,
  isPageDisabled,
  addCostTrackerItem,
  deleteCostTrackerItem,
  costItems,
}) => {
  const { t } = useTranslation();
  const { project: projectId } = useParams();
  const debounceUpdateTask = useRef(debounce(updateCostTrackerItem, 500));

  const rows = [];

  const [selectedRowId, setSelectedRowId] = useState('');
  const [expandedOperationIds, setExpandedOperationIds] = useState([]);

  useEffect(() => {
    if (expandedOperationIds?.length)
      setExpandedOperationIds(itemsList.map(({ sectionId }) => sectionId));
  }, [itemsList]);

  const toggleSelectedRow = (sectionId) =>
    selectedRowId === sectionId
      ? setSelectedRowId('')
      : setSelectedRowId(sectionId);

  const toggleExpandedRow = (sectionId) =>
    expandedOperationIds.includes(sectionId)
      ? setExpandedOperationIds(
          expandedOperationIds.filter((id) => id !== sectionId),
        )
      : setExpandedOperationIds([...expandedOperationIds, sectionId]);

  itemsList?.forEach((section) => {
    const isExpanded = expandedOperationIds.includes(section.sectionId);
    rows.push({
      key: section.sectionId,
      noDrag: true,
      cells: buildCostTrackerItem({
        data: getSetionData(section),
        disabledFields,
        isOperation: false,
        isExpanded,
      }),
      onRowClick: () => toggleExpandedRow(section.sectionId),
    });

    section.operations.forEach((operation) => {
      if (!expandedOperationIds.includes(operation.sectionId)) {
        rows.push({
          key: operation.costTrackerItemId,
          costTrackerItemId: operation.costTrackerItemId,
          sectionId: operation.sectionId,
          active: selectedRowId === operation.costTrackerItemId,
          onRowClick: () => toggleSelectedRow(operation.costTrackerItemId),
          cells: buildCostTrackerItem({
            data: operation,
            disabledFields,
            isOperation: true,
            cellValueUpdated,
            updateTask: debounceUpdateTask,
            costsArray: costItems,
          }),
          actions: [
            {
              subActions: [
                {
                  icon: 'add above',
                  label: t(translations.timeTracker_addTaskAbove),
                  onClick: () =>
                    addCostTrackerItem({
                      insertType: addTaskOptions.ABOVE,
                      costTrackerItemId: operation.costTrackerItemId,
                      projectId,
                    }),
                  disabled: true,
                },
                {
                  icon: 'add below',
                  label: t(translations.timeTracker_addTaskBelow),
                  onClick: () =>
                    addCostTrackerItem({
                      insertType: addTaskOptions.BELOW,
                      costTrackerItemId: operation.costTrackerItemId,
                      projectId,
                    }),
                  disabled: true,
                },
                {
                  icon: 'minus',
                  label: t(translations.delete),
                  onClick: () =>
                    deleteCostTrackerItem(
                      projectId,
                      operation.costTrackerId,
                      operation.costTrackerItemId,
                    ),
                  disabled: true,
                },
              ],
            },
          ],
        });
      }
    });
  });
  const onListReorder = (reorderData) => {
    const { from, to } = reorderData;
    const fromItemId = rows[from]?.costTrackerItemId;
    const toItemId = rows[to]?.costTrackerItemId;
    const fromSectionId = rows[from]?.sectionId;
    const toSectionId = rows[to]?.sectionId;

    if (fromSectionId && toSectionId && fromSectionId === toSectionId) {
      reorderCostTrackerList(projectId, {
        fromItemId,
        toItemId,
      });
    }
  };

  const tableWithoutData = {
    actions: [
      {
        icon: 'upload',
        tooltip: t(translations.timeTracker_upload),
        onClick: () => null,
        disabled: true,
      },
    ],
    name: t(translations.costTracker_costTracker),
    actionsRight: true,
  };

  const table = {
    maxHeight: 'calc(100% - 16px)',
    draggable: !isPageDisabled,
    actions: [
      {
        icon: 'download',
        tooltip: t(translations.timeTracker_download),
        onClick: () => null,
        disabled: true,
      },
      {
        icon: 'upload',
        tooltip: t(translations.timeTracker_upload),
        onClick: () => null,
        disabled: true,
      },
      {
        childComponent: <EditColumns />,
      },
    ],
    name: t(translations.costTracker_costTracker),
    actionsRight: true,
    columnWidths: getColumnWidth(disabledFields),
    columnAlignment: getColumnAlignment(disabledFields),
    headers: buildHeaders(disabledFields),
    rows,
  };

  return itemsList?.length ? (
    <div style={{ maxHeight: 'calc(100% - 93px)', height: '100%' }}>
      <Table table={table} onListReorder={onListReorder} />
    </div>
  ) : (
    <>
      <Table table={tableWithoutData} />
      <Flex
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 127px)"
      >
        <Heading top>{t(translations.timeTracker_noOperationsFound)}</Heading>
      </Flex>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  return {
    disabledFields: selectDisabledFields(entities),
    costItems: selectCostItemList({ entities }),
  };
};

const mapDispatchToProps = {
  cellValueUpdated,
  updateCostTrackerItem,
  reorderCostTrackerList,
  addCostTrackerItem,
  deleteCostTrackerItem,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(OperationsTable),
);

export { Container as OperationsTable };
