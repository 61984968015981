export enum costTrackerFields {
    NAME = 'name',
    START = 'start',
    TIME_AFE = 'timeAfe',
    COST_AFE = 'costAfe',
    TIME_ACTUAL = 'timeActual',
    COST_ACTUAL = 'costActual',
    VARIANCE = 'variance',
    CUMULATIVE_COST = 'cumulativeCost'
  };
  