import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Column,
  Heading,
  Spacer,
  Text,
  ButtonGroup,
  Flex,
  Popover,
  Badge,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { DateTimePicker } from '~src/views/cost-tracker/date-time-picker/date-time-picker';
import { round } from '@oliasoft-open-source/units';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { formatTimeToUI } from '~views/time-tracker/utils/date/dateUtils';
import {
  selectCostTrackerList,
  selectCostTrackerStart,
  selectCostTrackerFinish,
} from '~src/store/entities/cost-tracker/selectors';
import { thousandsSeparators } from './utils/utils';

const CostTrackerHeader = ({
  well,
  startDate,
  finishDate,
  totalAFE,
  expendedCost,
  estimatedCost,
  isPageDisabled,
  onViewModeChange,
  viewMode,
  costTrackerId,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex gap justifyContent="space-between">
        <Flex gap="20px">
          <Badge title="BETA" small>
            <Heading top>{t(translations.costTracker_costTracker)}</Heading>
          </Badge>
          <ButtonGroup
            items={[
              { key: 1, icon: 'table' },
              { key: 0, icon: 'chart' },
            ]}
            small
            onSelected={(key) => onViewModeChange(key)}
            value={viewMode ? 1 : 0}
            disabled
          />
        </Flex>
      </Flex>
      <Spacer />
      <Row>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.wellName)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{well}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_startDateAndTime)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{formatTimeToUI(startDate)}</Text>
            <Spacer width="var(--padding-xxs)" />

            <Popover
              content={
                <DateTimePicker
                  startDate={startDate}
                  disabled={isPageDisabled}
                  costTrackerId={costTrackerId}
                />
              }
              placement="top-center"
              disabled={isPageDisabled}
              overflowContainer
            ></Popover>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.timeTracker_estimatedFinish)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{formatTimeToUI(finishDate)}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.costTracker_afeDuration)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{`${totalAFE} days`}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.costTracker_EstimatedCost)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{`${thousandsSeparators(estimatedCost)} USD`}</Text>
          </Row>
        </Column>
        <Column flex={false}>
          <Row spacing={0}>
            <Text bold>{t(translations.costTracker_ExpendedCost)}</Text>
          </Row>
          <Row spacing={0}>
            <Text>{`${thousandsSeparators(expendedCost)} USD`}</Text>
          </Row>
        </Column>
      </Row>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { well } = entities.projects.overview;
  const list = selectCostTrackerList(entities) ?? [];
  const costTrackerId = list?.[0]?.operations?.[0]?.costTrackerId || '';
  const flatList = list?.map((section) => section.operations).flat();
  const totalAFE = round(
    flatList.reduce((acc, operation) => acc + operation.timeAfe, 0) / 24,
    2,
  );
  const expendedCost = round(
    flatList.reduce((acc, operation) => acc + operation.costActual || 0, 0),
    0,
  );
  const estimatedCost = round(
    flatList.reduce((acc, operation) => acc + operation.costAfe || 0, 0),
    0,
  );
  return {
    startDate: selectCostTrackerStart(entities),
    finishDate: selectCostTrackerFinish(entities),
    well,
    totalAFE,
    expendedCost,
    estimatedCost,
    costTrackerId,
  };
};

const mapDispatchToProps = {};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CostTrackerHeader),
);

export { Container as CostTrackerHeader };
