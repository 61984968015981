import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { apiCallBegan } from '~store/middleware/api/api';
import { reorderOperation } from '~store/utils-store';
import {
  initialKpi,
  initialTemplate,
  initialOperation,
  initialActivity,
  initialCostItem,
} from '~store/entities/user-settings/user-settings';
import { v4 as uuidv4 } from 'uuid';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import {
  nextName,
  updateActivityIds,
  updateCostSetupIds,
  findConnectedCategories,
} from '../user-settings/user-settings.utils';

const companySettings = createSlice({
  name: 'companySettings',
  initialState: {
    isAdding: false,
    isFetching: false,
    isUpdating: false,
    showTemplatesForm: false,
    showOperationsForm: false,
    showActivityForm: false,
    showDeleteOperationsModal: false,
    showDeleteActivitiesModal: false,
    showDeleteCostItemModal: false,
    showImportOperationModal: false,
    showDeleteCostCategoryModal: false,
    showKpiForm: false,
    showDeleteKpiModal: false,
    showAddOperationFromSectionsDataModal: false,
    showAddActivityFromSectionsDataModal: false,
    sectionTypes: [],
    settings: {
      currencies: { primaryCurrency: 'USD', additionalCurrencies: [] },
      kpi: [],
      templates: [initialTemplate],
    },
    currenciesList: [],
  },
  reducers: {
    companySettingsRequested: (companySettings) => {
      companySettings.isFetching = true;
      companySettings.settings = {};
    },
    companySettingsReceived: (companySettings, action) => {
      companySettings.isFetching = false;
      companySettings.settings = action.payload;
    },
    companySettingsRequestFailed: (companySettings) => {
      companySettings.isFetching = false;
    },
    companySettingsSaveRequested: (companySettings) => {
      companySettings.isAdding = true;
    },
    companySettingsSaveReceived: (companySettings) => {
      companySettings.isAdding = false;
    },
    companySettingsSaveFailed: (companySettings) => {
      companySettings.isAdding = false;
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_errorAddingTemplate),
        },
      });
    },
    currenciesListRequested: (companySettings) => {
      companySettings.isFetching = true;
      companySettings.currenciesList = [];
    },
    currenciesListReceived: (companySettings, action) => {
      companySettings.isFetching = false;
      companySettings.currenciesList = action.payload.currencies;
    },
    currenciesListRequestFailed: (companySettings) => {
      companySettings.isFetching = false;
    },
    saveCurrenciesRequested: (companySettings, action) => {
      companySettings.isAdding = true;
      companySettings.settings.currencies.additionalCurrencies =
        action.payload.additionalCurrencies;
    },
    saveCurrenciesReceived: (companySettings) => {
      companySettings.isAdding = false;
    },
    saveCurrenciesRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    saveTimeTrackerReceived: (companySettings) => {
      companySettings.isAdding = false;
    },
    saveTimeTrackerRequested: (companySettings, action) => {
      companySettings.isAdding = true;
      companySettings.settings.timeTracker = action.payload.timeTrackerSettings;
    },
    saveTimeTrackerRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    kpiSaved: (companySettings, action) => {
      companySettings.isAdding = false;
      companySettings.showKpiForm = true;
      companySettings.settings = action.payload;
      companySettings.settings.kpi = companySettings.settings.kpi.map(
        (item, i) => {
          item.active = i === companySettings.settings.kpi.length - 1;
          return item;
        },
      );
    },
    companyKpiSelected: (companySettings, action) => {
      companySettings.settings.kpi = companySettings.settings.kpi.map(
        (item) => {
          item.active = item.id === action.payload;
          return item;
        },
      );
      companySettings.showKpiForm = true;
    },
    kpiRemoved: (companySettings, action) => {
      companySettings.settings = action.payload;
      companySettings.showKpiForm = false;
      companySettings.showDeleteKpiModal = false;
      companySettings.isAdding = false;
    },
    showKpiFormUpdated: (companySettings, action) => {
      companySettings.showKpiForm = action.payload;
    },
    showDeleteKpiModalUpdated: (companySettings, action) => {
      companySettings.showDeleteKpiModal = action.payload;
    },
    kpiUpdated: (companySettings, action) => {
      const { kpiIndex, kpi } = action.payload;
      companySettings.settings.kpi[kpiIndex] = kpi;
      companySettings.settings.kpi[kpiIndex].active = true;
    },
    templateSelected: (companySettings, action) => {
      companySettings.settings.templates =
        companySettings.settings.templates.map((item, i) => {
          item.active = i === action.payload;
          return item;
        });
      companySettings.showTemplatesForm = true;
    },
    templatesSaved: (companySettings, action) => {
      companySettings.isAdding = false;
      companySettings.showTemplatesForm = true;
      companySettings.settings = action.payload;
      companySettings.settings.templates =
        companySettings.settings.templates.map((item, i) => {
          item.active = i === companySettings.settings.templates.length - 1;
          return item;
        });
    },
    templateRemoved: (companySettings, action) => {
      companySettings.settings = action.payload;
      companySettings.showTemplatesForm = false;
      companySettings.isAdding = false;
    },
    templateUpdated: (companySettings, action) => {
      const { index, highLevelData } = action.payload;
      companySettings.settings.templates[index].highLevel = highLevelData;
    },
    showTemplatesFormUpdated: (companySettings, action) => {
      companySettings.showTemplatesForm = action.payload;
    },
    sectionTypesFetched: (companySettings, action) => {
      companySettings.sectionTypes = action.payload.linkedSections;
    },
    sectionTypeSelected: (companySettings, action) => {
      const { templates } = companySettings.settings;
      companySettings.sectionTypes = companySettings.sectionTypes.map(
        (item) => {
          item.active = item.linkedSectionId === action.payload;
          return item;
        },
      );
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      companySettings.settings.templates[activeTemplateIndex].activityModel =
        templates[activeTemplateIndex].activityModel.map((item) => {
          item.active = false;
          return item;
        });
    },
    showOperationsFormUpdated: (companySettings, action) => {
      companySettings.showOperationsForm = action.payload;
    },
    showDeleteOperationsModalUpdated: (companySettings, action) => {
      companySettings.showDeleteOperationsModal = action.payload.visible;
      companySettings.selectedId = action.payload.selectedId;
    },
    operationSaveRequested: (companySettings, action) => {
      const { templateIndex, operation } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].activityModel.push(
        operation,
      );
      companySettings.settings.templates[templateIndex].activityModel.map(
        (item) => (item.active = item.id === operation.id),
      );
    },
    operationSaved: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showOperationsForm = true;
    },
    operationSaveRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    operationUpdateRequested: (companySettings, action) => {
      const { templateIndex, operationIndex, data } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ] = data;
    },
    operationUpdated: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showOperationsForm = false;
    },
    operationUpdateRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    operationSelected: (companySettings, action) => {
      const { templates } = companySettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      companySettings.settings.templates[activeTemplateIndex].activityModel =
        templates[activeTemplateIndex].activityModel.map((item) => {
          item.active = item.id === action.payload;
          return item;
        });
    },
    operationRemoved: (companySettings, action) => {
      const { operationId, templateIndex } = action.payload;
      const { activityModel } =
        companySettings.settings.templates[templateIndex];
      companySettings.settings.templates[
        action.payload.templateIndex
      ].activityModel = activityModel.filter(
        (operation) => operation.id !== operationId,
      );
      companySettings.isAdding = false;
      companySettings.showDeleteOperationsModal = false;
    },

    activitySaveRequested: (companySettings, action) => {
      const { templateIndex, operationIndex, activity } = action.payload;
      const { templates } = companySettings.settings;
      companySettings.isAdding = true;
      templates[templateIndex].activityModel[operationIndex].activities.push(
        activity,
      );
      templates[templateIndex].activityModel[operationIndex].activities =
        templates[templateIndex].activityModel[operationIndex].activities.map(
          (item) => {
            item.active = item.id === activity.id;
            return item;
          },
        );
    },
    activitySaveRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    activitySaved: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showActivityForm = true;
    },
    activityRemoved: (companySettings, action) => {
      const { templateIndex, operationIndex, deleteResult } = action.payload;
      companySettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities = deleteResult;

      companySettings.isAdding = false;
      companySettings.showDeleteActivitiesModal = false;
    },
    showDeleteActivitiesModalUpdated: (companySettings, action) => {
      companySettings.showDeleteActivitiesModal = action.payload.visible;
      companySettings.selectedId = action.payload.selectedId;
    },
    activitiesReorderRequested: (companySettings, action) => {
      const { templateIndex, operationIndex, activities } = action.payload;
      companySettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities = activities;
    },
    showActivitiesForm: (companySettings, action) => {
      const { templates } = companySettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      const activeOperationIndex = templates[
        activeTemplateIndex
      ].activityModel.findIndex((item) => item.active);
      companySettings.settings.templates[activeTemplateIndex].activityModel[
        activeOperationIndex
      ].activities = templates[activeTemplateIndex].activityModel[
        activeOperationIndex
      ].activities.map((item) => {
        item.active = item.id === action.payload;
        return item;
      });
      companySettings.showActivityForm = true;
    },
    closeActivitiesForm: (companySettings) => {
      companySettings.showActivityForm = false;
    },
    activityUpdateRequested: (companySettings, action) => {
      const { templateIndex, operationIndex, settingsData } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities =
        settingsData.templates[templateIndex].activityModel[
          operationIndex
        ].activities;
    },
    activityUpdated: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showActivityForm = false;
    },
    activityUpdateRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    showImportOperationModalUpdated: (companySettings, action) => {
      companySettings.showImportOperationModal = action.payload;
    },
    operationsImportRequested: (companySettings, action) => {
      const { templateIndex, mergedOperations } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].activityModel.push(
        ...mergedOperations,
      );
    },
    operationsImported: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showImportOperationModal = false;
    },
    operationsImportRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    templateReorder: (companySettings, action) => {
      const { templates } = action.payload;
      companySettings.settings.templates = templates;
      companySettings.showTemplatesForm = false;
      companySettings.isAdding = false;
    },

    operationReorder: (companySettings, action) => {
      const { response, activeTemplateIndex } = action.payload;
      companySettings.settings.templates[activeTemplateIndex].activityModel =
        response.templates[activeTemplateIndex].activityModel;
      companySettings.showTemplatesForm = true;
      companySettings.isAdding = false;
    },

    addCostItemRequested: (companySettings, action) => {
      const { templateIndex, costItem } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].costSetup.costItem.push(
        costItem,
      );
      companySettings.selectedId = costItem.costItemId;
    },
    addCostItemSaved: (companySettings) => {
      companySettings.isAdding = false;
    },
    addCostItemRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    costItemRemoved: (companySettings, action) => {
      const { costItemIndex, templateIndex } = action.payload;
      const { costItem } =
        companySettings.settings.templates[templateIndex].costSetup;
      costItem.splice(costItemIndex, 1);
      companySettings.settings.templates[templateIndex].costSetup.costItem =
        costItem;
      companySettings.isAdding = false;
    },
    showDeleteCostItemModalUpdated: (companySettings, action) => {
      companySettings.showDeleteCostItemModal = action.payload.visible;
      companySettings.selectedId = action.payload.selectedId;
    },
    costItemSelected: (companySettings, action) => {
      const { templates } = companySettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      companySettings.selectedId = companySettings.settings.templates[
        activeTemplateIndex
      ].costSetup.costItem.find(
        (item) => item.costItemId === action.payload,
      ).costItemId;
    },

    costItemUpdatedRequested: (companySettings, action) => {
      const { templateIndex, costItemIndex, data } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].costSetup.costItem[
        costItemIndex
      ] = data;
    },
    costItemUpdated: (companySettings) => {
      companySettings.isAdding = false;
    },
    costItemUpdatedRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    addCostCategoryRequested: (companySettings, action) => {
      const { templateIndex, costCategory } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[
        templateIndex
      ].costSetup.costCategory.push(costCategory);
      companySettings.selectedId = costCategory.costCategoryId;
    },
    addCostCategorySaved: (companySettings) => {
      companySettings.isAdding = false;
    },
    addCostCategoryRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    costCategorySelected: (companySettings, action) => {
      const { templates } = companySettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      companySettings.selectedId = companySettings.settings.templates[
        activeTemplateIndex
      ].costSetup.costCategory.find(
        (item) => item.costCategoryId === action.payload,
      ).costCategoryId;
    },
    costCategoryUpdatedRequested: (companySettings, action) => {
      const { templateIndex, costCategoryIndex, data } = action.payload;
      companySettings.isAdding = true;
      companySettings.settings.templates[templateIndex].costSetup.costCategory[
        costCategoryIndex
      ] = data;
    },
    costCategoryUpdated: (companySettings) => {
      companySettings.isAdding = false;
    },
    costCategoryUpdatedRequestFailed: (companySettings) => {
      companySettings.isAdding = false;
    },
    showDeleteCostCategoryModalUpdated: (companySettings, action) => {
      companySettings.showDeleteCostCategoryModal = action.payload.visible;
      companySettings.selectedId = action.payload.selectedId;
    },
    costCategoryRemoved: (companySettings, action) => {
      const { templateIndex, filteredCostSetup } = action.payload;
      companySettings.settings.templates[templateIndex].costSetup =
        filteredCostSetup;
      companySettings.isAdding = false;
      companySettings.showDeleteActivitiesModal = false;
    },
    costSetupReorderRequested: (companySettings, action) => {
      const { data, templateIndex } = action.payload;
      companySettings.settings.templates[templateIndex].costSetup = data;
    },
    showAddOperationFromSectionsDataModalUpdated: (companySettings, action) => {
      companySettings.showAddOperationFromSectionsDataModal = action.payload;
    },
    addOperationFromSectionsDataSaved: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showAddOperationFromSectionsDataModal = false;
    },
    showAddActivityFromSectionsDataModalUpdated: (companySettings, action) => {
      companySettings.showAddActivityFromSectionsDataModal = action.payload;
    },
    addActivityFromSectionsDataSaved: (companySettings) => {
      companySettings.isAdding = false;
      companySettings.showAddActivityFromSectionsDataModal = false;
    },
  },
});

export const {
  companySettingsRequested,
  companySettingsReceived,
  companySettingsRequestFailed,
  companySettingsSaveRequested,
  companySettingsSaveReceived,
  companySettingsSaveFailed,
  currenciesListRequested,
  currenciesListReceived,
  currenciesListRequestFailed,
  saveCurrenciesRequested,
  saveCurrenciesRequestFailed,
  saveCurrenciesReceived,
  saveTimeTrackerRequested,
  saveTimeTrackerRequestFailed,
  saveTimeTrackerReceived,
  kpiSaved,
  companyKpiSelected,
  kpiRemoved,
  showKpiFormUpdated,
  showDeleteKpiModalUpdated,
  kpiUpdated,
  templateSelected,
  templatesSaved,
  templateRemoved,
  templateUpdated,
  showTemplatesFormUpdated,
  sectionTypeSelected,
  showOperationsFormUpdated,
  showDeleteOperationsModalUpdated,
  operationSaveRequested,
  operationSaveRequestFailed,
  operationSaved,
  operationUpdateRequested,
  operationUpdateRequestFailed,
  operationUpdated,
  operationSelected,
  operationRemoved,
  operationReorder,
  activitySaveRequested,
  activitySaveRequestFailed,
  activitySaved,
  activityRemoved,
  showDeleteActivitiesModalUpdated,
  activitiesReorderRequested,
  showActivitiesForm,
  closeActivitiesForm,
  activityUpdateRequested,
  activityUpdated,
  activityUpdateRequestFailed,
  showImportOperationModalUpdated,
  operationsImportRequested,
  operationsImported,
  operationsImportRequestFailed,
  templateReorder,
  addCostItemRequested,
  addCostItemSaved,
  addCostItemRequestFailed,
  costItemRemoved,
  showDeleteCostItemModalUpdated,
  costItemSelected,
  costItemUpdatedRequested,
  costItemUpdated,
  costItemUpdatedRequestFailed,
  addCostCategoryRequested,
  addCostCategorySaved,
  addCostCategoryRequestFailed,
  costCategorySelected,
  costCategoryUpdatedRequested,
  costCategoryUpdated,
  costCategoryUpdatedRequestFailed,
  showDeleteCostCategoryModalUpdated,
  costCategoryRemoved,
  costSetupReorderRequested,
  sectionTypesFetched,
  showAddOperationFromSectionsDataModalUpdated,
  addOperationFromSectionsDataSaved,
  showAddActivityFromSectionsDataModalUpdated,
  addActivityFromSectionsDataSaved,
} = companySettings.actions;
export default companySettings.reducer;

/**
 * Get company settings
 *
 * @param companyId
 */
export const getCompanySettings = (companyId) =>
  apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'GET',
    onStart: companySettingsRequested.type,
    onSuccess: companySettingsReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToGetCompanySettings),
        },
      });
      return { type: companySettingsRequestFailed.type };
    },
  });

/**
 *  Get currencies list
 */
export const getCurrencies = () =>
  apiCallBegan({
    url: '/api/currencies',
    method: 'GET',
    onStart: currenciesListRequested.type,
    onSuccess: currenciesListReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToGetCurrencies),
        },
      });
      return { type: currenciesListRequestFailed.type };
    },
  });

/**
 * Save currencies
 *
 * @param companyId
 * @param additionalCurrencies
 * @param settings
 */
export const saveCurrencies = (companyId, additionalCurrencies, settings) => {
  const data = cloneDeep(settings);
  data.currencies.additionalCurrencies = additionalCurrencies;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: saveCurrenciesRequested.type,
      payload: { additionalCurrencies },
    }),
    onSuccess: saveCurrenciesReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToSaveCurrencies),
        },
      });
      return { type: saveCurrenciesRequestFailed.type };
    },
  });
};

/**
 * update time tracker settings
 *
 * @param companyId
 * @param timeTrackerSettings
 * @param settings
 */
export const saveTimeTracker = (companyId, timeTrackerSettings, settings) => {
  const data = cloneDeep(settings);
  data.timeTracker = timeTrackerSettings;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: saveTimeTrackerRequested.type,
      payload: { timeTrackerSettings },
    }),
    onSuccess: saveTimeTrackerReceived.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToSaveTimeTracker),
        },
      });
      return { type: saveTimeTrackerRequestFailed.type };
    },
  });
};

/**
 * Adds an initial Key Performance Indicator (KPI) to the company settings.
 *
 * @param {string} companyId
 * @param {string} name - The name of the KPI.
 * @param {object} settings - The user settings.
 * @returns {Object} The API call action object.
 */
export const addInitialCompanyKpi = (name, settings, companyId) => {
  const data = cloneDeep(settings);
  const { kpi: kpis } = data;
  const newName = nextName(
    kpis.map((kpi) => kpi.name),
    name,
  );

  const kpi = {
    ...initialKpi,
    name: newName,
    id: uuidv4(),
  };
  data.kpi = [...data.kpi, kpi];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data.kpi = data.kpi.map(({ active, ...rest }) => rest);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: kpiSaved.type,
      payload: response,
    }),
    onError: companySettingsSaveFailed.type,
  });
};

/**
 * Removes a KPI from company settings.
 *
 * @param {string} companyId
 * @param {string} id - The ID of the KPI to be removed.
 * @param {Object} settings - The user settings object.
 * @returns {Object} The API call action object.
 */
export const removeCompanyKpi = (id, settings, companyId) => {
  const data = cloneDeep(settings);
  const index = data.kpi.findIndex((item) => item.id === id);
  data.kpi.splice(index, 1);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: kpiRemoved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 * Updates the KPI with the given value and sends an API request to update the settings.
 *
 * @param {string} companyId
 * @param {Object} kpi - The new KPI value to update.
 * @param {Object} settings - The current settings object to update.
 * @returns {Object} The API call action object.
 */
export const updateCompanyKpi = (kpi, settings, companyId) => {
  const data = cloneDeep(settings);
  const kpiIndex = data.kpi.findIndex((item) => item.active);

  if (
    data.kpi.some(
      (k) =>
        k.name.toUpperCase().trim() === kpi.name.toUpperCase().trim() &&
        !k.active,
    )
  ) {
    toast({
      message: {
        type: 'Error',
        heading: i18n.t(translations.settings_failedToUpdate),
        content: i18n.t(translations.settings_nameMustBeUnique, {
          type: i18n.t(translations.kpi),
          name: kpi.name,
        }),
      },
    });
    kpi.name = data.kpi[kpiIndex].name;
  }
  data.kpi[kpiIndex] = kpi;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onSuccess: () => ({
      type: kpiUpdated.type,
      payload: { kpiIndex, kpi },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
    },
  });
};

/**
 * Add initial template
 *
 * @param name
 * @param settings
 * @param companyId
 */
export const addInitialTemplate = (name, settings, companyId) => {
  const data = { ...settings };
  const { templates } = data;
  const newName = nextName(
    templates.map((template) => template.highLevel.name),
    name,
  );
  const template = {
    templateId: uuidv4(),
    highLevel: { name: newName, ...initialTemplate.highLevel },
    activityModel: [],
    costSetup: {},
  };
  data.templates = [...data.templates, template];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data.templates = data.templates.map(({ active, ...rest }) => rest);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
    onError: companySettingsSaveFailed.type,
  });
};

/**
 * Duplicate Template
 *
 * @param settings
 * @param index
 * @param companyId
 *
 */
export const duplicateTemplate = (settings, index, companyId) => {
  const data = cloneDeep(settings);
  const { templates } = data;
  const duplicatedTemplate = cloneDeep(templates[index]);
  const newName = nextName(
    templates.map((template) => template.highLevel.name),
    duplicatedTemplate.highLevel.name,
  );

  if (!duplicatedTemplate.costSetup) {
    duplicatedTemplate.costSetup = initialTemplate.costSetup;
  }

  const { costCategory, costItem } = duplicatedTemplate?.costSetup;

  costItem.forEach((item) => {
    const newItemId = uuidv4();
    item.costItemId = newItemId;
  });

  costCategory.forEach((category) => {
    const { costCategoryId } = category;
    const newCategoryId = uuidv4();
    category.costCategoryId = newCategoryId;

    updateCostSetupIds(costCategory, costCategoryId, newCategoryId);
    updateCostSetupIds(costItem, costCategoryId, newCategoryId);
  });

  duplicatedTemplate.activityModel = updateActivityIds(
    duplicatedTemplate.activityModel,
  );

  duplicatedTemplate.highLevel.name = newName;
  duplicatedTemplate.templateId = uuidv4();

  templates.push(duplicatedTemplate);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToDuplicateTemplate),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 * Upload Template
 *
 * @param settings
 * @param template
 * @param companyId
 *
 */
export const addTemplateFromUpload = (settings, template, companyId) => {
  const data = cloneDeep(settings);
  const { templates } = data;
  const newName = nextName(
    templates.map((template) => template.highLevel.name),
    template.highLevel.name,
  );
  const newTemplate = { ...template, templateId: uuidv4() };
  newTemplate.highLevel.name = newName;

  templates.push(newTemplate);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
    onError: companySettingsSaveFailed.type,
  });
};

/**
 * Reorder template
 *
 * @param fromIndex
 * @param toIndex
 * @param settings
 * @param companyId
 */
export const reorderTemplate = (fromIndex, toIndex, settings, companyId) => {
  if (fromIndex === toIndex) return;
  const data = cloneDeep(settings);
  const [movedItem] = data.templates.splice(fromIndex, 1);
  data.templates.splice(toIndex, 0, movedItem);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templateReorder.type,
      payload: response,
    }),
    onError: companySettingsSaveFailed.type,
  });
};

/**
 * Reorder operations
 *
 * @param reorder
 * @param settings
 * @param companyId
 */

export const reorderOperationsInSettings = (reorder, settings, companyId) => {
  const result = reorderOperation(reorder, settings);
  if (!result) {
    return;
  }
  const { updatedSettings, activeTemplateIndex } = result;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: updatedSettings,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => {
      return {
        type: operationReorder.type,
        payload: { response, activeTemplateIndex },
      };
    },
  });
};

/**
 * Remove template
 *
 * @param index
 * @param settings
 * @param companyId
 */
export const removeTemplate = (index, settings, companyId) => {
  const data = cloneDeep(settings);
  data.templates.splice(index, 1);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templateRemoved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 * Update template
 *
 * @param index
 * @param settings
 * @param highLevelData
 * @param companyId
 */
export const updateTemplate = (index, settings, highLevelData, companyId) => {
  const data = cloneDeep(settings);
  if (
    data.templates.some(
      (template, i) =>
        template.highLevel.name.toUpperCase().trim() ===
          highLevelData.name.toUpperCase().trim() && i !== index,
    )
  ) {
    toast({
      message: {
        type: 'Error',
        content: i18n.t(translations.settings_nameMustBeUnique, {
          type: i18n.t(translations.template),
          name: highLevelData.name,
        }),
      },
    });
    highLevelData.name = data.templates[index].highLevel.name;
  }
  data.templates[index].highLevel = highLevelData;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onSuccess: () => ({
      type: templateUpdated.type,
      payload: { index, highLevelData },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
    },
  });
};

/**
 * Add initial operation
 *
 * @param name
 * @param settings
 * @param companyId
 */
export const addInitialOperation = (name, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.settings.templates.findIndex(
    (item) => item.active,
  );
  const sectionType = data.sectionTypes.find((item) => item.active);
  const operation = {
    ...initialOperation,
    id: uuidv4(),
    sectionType: sectionType.sectionId,
    name,
    linkedSectionType: sectionType.linkedSectionId,
  };
  data.settings.templates[templateIndex].activityModel.push(operation);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: data.settings,
    onStart: () => ({
      type: operationSaveRequested.type,
      payload: { templateIndex, operation },
    }),
    onSuccess: (response) => ({
      type: operationSaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.setting_failedToAddInitialOperation),
        },
      });
      return { type: operationSaveRequestFailed.type };
    },
  });
};

/**
 * Add initial activity
 *
 * @param name
 * @param settings
 * @param parentId
 * @param companyId
 */
export const addInitialActivity = (
  name,
  settings,
  parentId = 0,
  sectionsOperationActivityId = null,
  companyId,
) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const activity = {
    ...initialActivity,
    id: uuidv4(),
    parentId,
    name,
    sectionsOperationActivityId,
  };
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  data.templates[templateIndex].activityModel[operationIndex].activities.push(
    activity,
  );

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: activitySaveRequested.type,
      payload: { templateIndex, operationIndex, activity },
    }),
    onSuccess: (response) => ({
      type: activitySaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.setting_failedToAddInitialActivity),
        },
      });
      return { type: activitySaveRequestFailed.type };
    },
  });
};

/**
 * Reorder activities
 *
 * @param orderedTasks
 * @param settings
 * @param companyId
 */
export const reorderActivities = (orderedTasks, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );

  const orderedTasksMap = orderedTasks.map((task, i) => ({
    id: task.id,
    parentId: task.parent,
    position: i,
  }));

  const activities = data.templates[templateIndex].activityModel[
    operationIndex
  ].activities.map((item) => {
    const reorderedTask = orderedTasksMap.find((task) => task.id === item.id);

    if (reorderedTask) {
      return {
        ...item,
        parentId: reorderedTask.parentId,
        position: reorderedTask.position,
      };
    }
    return item;
  });
  activities.sort((a, b) => a.position - b.position);
  data.templates[templateIndex].activityModel[operationIndex].activities =
    activities;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: activitiesReorderRequested.type,
      payload: { templateIndex, operationIndex, activities },
    }),
  });
};

/**
 * Remove activity
 *
 * @param id
 * @param settings
 * @param companyId
 */
export const removeActivity = (id, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  const { activities } =
    data.templates[templateIndex].activityModel[operationIndex];
  const deleteActivityById = (id, activities) => {
    const index = activities.findIndex((item) => item.id === id);

    if (index !== -1) {
      activities.splice(index, 1);
      const childItems = activities.filter((item) => item.parentId === id);
      childItems.forEach((childItem) =>
        deleteActivityById(childItem.id, activities),
      );
    }

    return activities;
  };
  const deleteResult = deleteActivityById(id, activities);
  data.templates[templateIndex].activityModel[operationIndex].activities =
    deleteResult;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: () => ({
      type: activityRemoved.type,
      payload: { templateIndex, operationIndex, deleteResult },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 * Remove operation
 *
 * @param index
 * @param settings
 * @param companyId
 */
export const removeOperation = (operationId, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const filteredOperation = data.templates[templateIndex].activityModel.filter(
    (operation) => operation.id !== operationId,
  );
  data.templates[templateIndex].activityModel = filteredOperation;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: () => ({
      type: operationRemoved.type,
      payload: { operationId, templateIndex },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 *  Update operation
 *
 * @param data
 * @param settings
 * @param companyId
 */
export const updateOperation = (data, settings, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const operationIndex = settingsData.templates[
    templateIndex
  ].activityModel.findIndex((item) => item.active);
  settingsData.templates[templateIndex].activityModel[operationIndex] = data;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: operationUpdateRequested.type,
      payload: { templateIndex, operationIndex, data },
    }),
    onSuccess: (response) => ({
      type: operationUpdated.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
      return { type: operationUpdateRequestFailed.type };
    },
  });
};

/**
 * Update activity
 *
 * @param data
 * @param settings
 * @param companyId
 */
export const updateActivity = (data, branches, settings, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const operationIndex = settingsData.templates[
    templateIndex
  ].activityModel.findIndex((item) => item.active);
  const activityIndex = settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities.findIndex((item) => item.active);
  settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities[activityIndex] = data;

  const activities = settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities.filter(
    (activity) => !(activity?.isBranch && activity?.parentId === data.id),
  );

  settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities = [...activities, ...(data?.withBranch ? branches : [])];

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: activityUpdateRequested.type,
      payload: { templateIndex, operationIndex, settingsData },
    }),
    onSuccess: (response) => ({
      type: activityUpdated.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
      return { type: activityUpdateRequestFailed.type };
    },
  });
};

export const importOperations = (
  data,
  templateIndex,
  section,
  settings,
  companyId,
) => {
  const settingsData = cloneDeep(settings);
  const mergedOperations = data.operations.map((id) => {
    const copied = settingsData.templates[data.template].activityModel.find(
      (operation) => operation.id === id,
    );
    return { ...copied, id: uuidv4(), sectionType: section.sectionId };
  });
  settingsData.templates[templateIndex].activityModel.push(...mergedOperations);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: operationsImportRequested.type,
      payload: { templateIndex, mergedOperations },
    }),
    onSuccess: (response) => ({
      type: operationsImported.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToImportOperations),
        },
      });
      return { type: operationsImportRequestFailed.type };
    },
  });
};

/**
 * Add initial cost item
 *
 * @param name
 * @param settings
 * @param parentId
 * @param companyId
 */
export const addCostItem = (name, settings, parentId = 0, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.settings.templates.findIndex(
    (item) => item.active,
  );
  const costItem = {
    ...initialCostItem,
    costItemId: uuidv4(),
    parentId,
    name,
  };
  data.settings.templates[templateIndex].costSetup.costItem.push(costItem);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: data.settings,
    onStart: () => ({
      type: addCostItemRequested.type,
      payload: { templateIndex, costItem },
    }),
    onSuccess: addCostItemSaved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToAdd),
        },
      });
      return { type: addCostItemRequestFailed.type };
    },
  });
};

/**
 * Remove cost item
 *
 * @param name
 * @param settings
 * @param companyId
 */
export const removeCostItem = (id, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const costItemIndex = data.templates[
    templateIndex
  ].costSetup.costItem.findIndex((item) => item.costItemId === id);
  data.templates[templateIndex].costSetup.costItem.splice(costItemIndex, 1);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: companySettingsSaveRequested.type,
    onSuccess: () => ({
      type: costItemRemoved.type,
      payload: { costItemIndex, templateIndex },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 *  Update cost item
 *
 * @param data
 * @param settings
 * @param selectedId
 * @param companyId
 */
export const updateCostItem = (data, settings, selectedId, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const costItemIndex = settingsData.templates[
    templateIndex
  ].costSetup.costItem.findIndex((item) => item.costItemId === selectedId);
  settingsData.templates[templateIndex].costSetup.costItem[costItemIndex] =
    data;
  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costItemUpdatedRequested.type,
      payload: { templateIndex, costItemIndex, data },
    }),
    onSuccess: costItemUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
      return { type: costItemUpdatedRequestFailed.type };
    },
  });
};

/**
 *  Add cost category
 *
 * @param data
 * @param settings
 * @param companyId
 */
export const addCostCategory = (data, settings, companyId) => {
  const { name, parentId } = data;
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.settings.templates.findIndex(
    (item) => item.active,
  );
  const costCategory = {
    costCategoryId: uuidv4(),
    parentId,
    name,
  };
  settingsData.settings.templates[templateIndex].costSetup.costCategory.push(
    costCategory,
  );
  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData.settings,
    onStart: () => ({
      type: addCostCategoryRequested.type,
      payload: { templateIndex, costCategory },
    }),
    onSuccess: addCostCategorySaved.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToAdd),
        },
      });
      return { type: addCostCategoryRequestFailed.type };
    },
  });
};

/**
 *  Update cost category
 *
 * @param data
 * @param settings
 * @param selectedId
 * @param companyId
 */
export const updateCostCategory = (data, settings, selectedId, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const costCategoryIndex = settingsData.templates[
    templateIndex
  ].costSetup.costCategory.findIndex(
    (item) => item.costCategoryId === selectedId,
  );
  settingsData.templates[templateIndex].costSetup.costCategory[
    costCategoryIndex
  ] = data;
  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costCategoryUpdatedRequested.type,
      payload: { templateIndex, costCategoryIndex, data },
    }),
    onSuccess: costCategoryUpdated.type,
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToUpdate),
        },
      });
      return { type: costCategoryUpdatedRequestFailed.type };
    },
  });
};

/**
 *  Remove cost category
 *
 * @param data
 * @param settings
 * @param companyId
 */
export const removeCostCategory = (costCategoryId, settings, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.settings.templates.findIndex(
    (item) => item.active,
  );
  const { costSetup } = settingsData.settings.templates[templateIndex];
  const filteredCostSetup = findConnectedCategories(costSetup, costCategoryId);
  settingsData.settings.templates[templateIndex].costSetup = filteredCostSetup;
  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData.settings,
    onStart: companySettingsSaveRequested.type,
    onSuccess: () => ({
      type: costCategoryRemoved.type,
      payload: { templateIndex, filteredCostSetup },
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToRemove),
        },
      });
      return { type: companySettingsSaveFailed.type };
    },
  });
};

/**
 * Reorder cost elements
 *
 * @param orderedElements
 * @param list
 * @param settings
 * @param companyId
 *
 */
export const reorderElements = (orderedElements, list, settings, companyId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const orderedElementsData = orderedElements.map((orderedElement, i) => ({
    ...(orderedElement.itemType === 'category'
      ? { costCategoryId: orderedElement.id }
      : { costItemId: orderedElement.id }),
    parentId: orderedElement.parent || null,
    position: i,
  }));
  const costSetupOrdered = {
    costItem: orderedElementsData.filter((item) => item.costItemId),
    costCategory: orderedElementsData.filter((item) => item.costCategoryId),
  };
  const costSetupUnordered = settingsData.templates[templateIndex].costSetup;
  const data = {
    costItem: [
      ...costSetupUnordered.costItem.map((oldItem) => {
        const newItem = costSetupOrdered.costItem.find(
          (item) => item.costItemId === oldItem.costItemId,
        );
        return newItem ? { ...oldItem, ...newItem } : oldItem;
      }),
      ...costSetupOrdered.costItem.filter(
        (newItem) =>
          !costSetupUnordered.costItem.find(
            (oldItem) => oldItem.costItemId === newItem.costItemId,
          ),
      ),
    ],
    costCategory: [
      ...costSetupUnordered.costCategory.map((oldCategory) => {
        const newCategory = costSetupOrdered.costCategory.find(
          (category) => category.costCategoryId === oldCategory.costCategoryId,
        );
        return newCategory ? { ...oldCategory, ...newCategory } : oldCategory;
      }),
      ...costSetupOrdered.costCategory.filter(
        (newCategory) =>
          !costSetupUnordered.costCategory.find(
            (oldCategory) =>
              oldCategory.costCategoryId === newCategory.costCategoryId,
          ),
      ),
    ],
  };

  settingsData.templates[templateIndex].costSetup = data;

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costSetupReorderRequested.type,
      payload: { data, templateIndex },
    }),
  });
};

/**
 * Retrieves the section types from the server.
 *
 * @returns {Object} The API call action object.
 */
export const getSectionTypes = () =>
  apiCallBegan({
    url: '/api/operations/linked-sections',
    method: 'GET',
    onSuccess: (response) => ({
      type: sectionTypesFetched.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToGetSectionTypes),
        },
      });
    },
  });

/**
 * Add operation from sections data structure.
 *
 * @param {object} operation - The operation object.
 * @param {object} settings - The settings object.
 * @param {string} sectionId - The section ID.
 * @param {string} companyId - The section ID.
 *
 * @returns {object} - Returns the result of the API call.
 */
export const addOperationFromSectionsData = (
  operation,
  settings,
  sectionId,
  companyId,
) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationData = {
    ...operation,
    id: uuidv4(),
    parentId: sectionId,
    sectionType: sectionId,
  };

  data.templates[templateIndex].activityModel.push(operationData);

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: operationSaveRequested.type,
      payload: { templateIndex, operation: operationData },
    }),
    onSuccess: (response) => ({
      type: addOperationFromSectionsDataSaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToAdd),
        },
      });
      return { type: operationSaveRequestFailed.type };
    },
  });
};

/**
 * Adds an activity from the sections data.
 *
 * @param {object} activity - The activity to add.
 * @param {object} settings - The settings data.
 * @param {string} companyId - The company ID.
 * @returns {object} - The API call result.
 */
export const addActivityFromSectionsData = (activity, settings, companyId) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const activityData = {
    ...activity,
    id: uuidv4(),
    parentId: 0,
  };
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  data.templates[templateIndex].activityModel[operationIndex].activities.push(
    activityData,
  );

  return apiCallBegan({
    url: `/api/settings/company/${companyId}`,
    method: 'POST',
    data,
    onStart: () => ({
      type: activitySaveRequested.type,
      payload: { templateIndex, operationIndex, activity: activityData },
    }),
    onSuccess: (response) => ({
      type: addActivityFromSectionsDataSaved.type,
      payload: response,
    }),
    onError: () => {
      toast({
        message: {
          type: 'Error',
          content: i18n.t(translations.settings_failedToAdd),
        },
      });
      return { type: activitySaveRequestFailed.type };
    },
  });
};
