import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router';
import { routes } from '~routes/routes';
import { ProjectSideBar } from '~views/navigation/project-side-bar/project-side-bar';
import { ActivityModel } from '~views/activity-model/activity-model';
import { Risks } from '~views/risks/risks';
import { CostSetup } from '~views/cost-setup/cost-setup';
import { NotFound } from '~views/not-found/not-found';
import ResultOverview from '~views/simulations/result-overview/result-overview';
import { Statistics } from '~views/simulations/statistics/statistics';
import { Overview } from '~views/overview/overview';
import { TimeTracker } from '~views/time-tracker/time-tracker';
import { CostTracker } from '~views/cost-tracker/cost-tracker';
import { WellDetails } from '~views/well-details/well-details';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const RawProject = () => {
  const { project } = useParams();

  return (
    <>
      <ProjectSideBar />
      <Routes>
        <Route {...routes.activityModel.pattern} element={<ActivityModel />} />
        <Route
          {...routes.risks.pattern}
          element={<Risks projectId={project} />}
        />
        <Route {...routes.costSetup.pattern} element={<CostSetup />} />
        <Route
          {...routes.resultOverview.pattern}
          element={<ResultOverview projectId={project} />}
        />
        <Route
          {...routes.statistics.pattern}
          element={<Statistics projectId={project} />}
        />
        <Route {...routes.overview.pattern} element={<Overview />} />
        <Route {...routes.timeTracker.pattern} element={<TimeTracker />} />
        <Route {...routes.costTracker.pattern} element={<CostTracker />} />
        <Route
          {...routes.wellConfiguration.pattern}
          element={<WellDetails />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export const Project = withErrorBoundary(RawProject, {
  style: { position: 'absolute', top: '50px' },
});
