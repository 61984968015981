import { createSelector } from '@reduxjs/toolkit';

const selectCostTracker = (entities) => entities.costTracker;

export const selectCostTrackerList = createSelector(
  [selectCostTracker],
  ({ list }) => list,
);

export const selectCostTrackerStart = createSelector(
  [selectCostTracker],
  ({ start }) => start,
);

export const selectCostTrackerFinish = createSelector(
  [selectCostTracker],
  ({ finish }) => finish,
);

export const selectDisabledFields = createSelector(
  [selectCostTracker],
  ({ disabledFields }) => disabledFields,
);
