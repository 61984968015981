import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';

export const routes = Object.freeze({
  root: {
    title: 'Root',
    pattern: {
      path: '/',
    },
  },
  companies: {
    title: 'Companies',
    fullPath: '/',
    pattern: {
      path: '/',
    },
  },
  hierarchy: {
    title: 'Hierarchy',
    fullPath: '/company/:company/project/:project?',
    pattern: {
      path: '/company/:company/project/:project?',
    },
  },
  project: {
    title: 'Project',
    fullPath: '/company/:company/project/:project/*',
    pattern: {
      path: 'company/:company/project/:project/*',
    },
  },
  overview: {
    title: 'Overview',
    fullPath: '/company/:company/project/:project/overview',
    pattern: {
      path: 'overview',
    },
  },
  wellConfiguration: {
    title: 'Well Details',
    fullPath: '/company/:company/project/:project/well-details',
    pattern: {
      path: 'well-details',
    },
  },
  activityModel: {
    title: 'Activity Model',
    fullPath: '/company/:company/project/:project/activity-model',
    pattern: {
      path: 'activity-model',
    },
  },
  risks: {
    title: 'Project Modifiers',
    fullPath: '/company/:company/project/:project/risks',
    pattern: {
      path: 'risks',
    },
  },
  costSetup: {
    title: 'Cost Setup',
    fullPath: '/company/:company/project/:project/cost-setup',
    pattern: {
      path: 'cost-setup',
    },
  },
  resultOverview: {
    title: 'Result Overview',
    fullPath: '/company/:company/project/:project/result-overview',
    pattern: {
      path: 'result-overview',
    },
  },
  statistics: {
    title: 'Statistics',
    fullPath: '/company/:company/project/:project/statistics',
    pattern: {
      path: 'statistics',
    },
  },
  timeTracker: {
    title: 'Time Tracker',
    fullPath: '/company/:company/project/:project/time-tracker',
    pattern: {
      path: 'time-tracker',
    },
  },
  costTracker: {
    title: i18n.t(translations.costTracker_costTracker),
    fullPath: 'company/:company/project/:project/cost-tracker',
    pattern: {
      path: 'cost-tracker',
    },
  },
  userSettings: {
    title: 'User settings',
    fullPath: '/user-settings/*',
    pattern: {
      path: '/user-settings/*',
    },
    nested: {
      units: {
        title: 'Units',
        fullPath: '/user-settings/units',
        pattern: {
          path: 'units',
        },
      },
      templates: {
        title: 'Templates',
        fullPath: '/user-settings/templates',
        pattern: {
          path: 'templates',
        },
      },
      kpi: {
        title: 'KPI',
        fullPath: '/user-settings/kpi',
        pattern: {
          path: 'kpi',
        },
      },
    },
  },
  companySettings: {
    title: 'Company settings',
    fullPath: '/company-settings/:company/*',
    pattern: {
      path: '/company-settings/:company/*',
    },
    nested: {
      currency: {
        title: 'Currency',
        fullPath: '/company-settings/:company/currency',
        pattern: {
          path: 'currency',
        },
      },
      operationCodes: {
        title: 'Operation codes',
        fullPath: '/company-settings/:company/operation-codes',
        pattern: {
          path: 'operation-codes',
        },
      },
      templates: {
        title: 'Templates',
        fullPath: '/company-settings/:company/templates',
        pattern: {
          path: 'templates',
        },
      },
      kpi: {
        title: 'KPI',
        fullPath: '/company-settings/:company/kpi',
        pattern: {
          path: 'kpi',
        },
      },
      timeTracker: {
        title: 'Time Tracker',
        fullPath: '/company-settings/:company/time-tracker',
        pattern: {
          path: 'time-tracker',
        },
      },
      sectionsDataStructure: {
        title: 'Data Structure',
        fullPath: '/company-settings/:company/sections-data-structure',
        pattern: {
          path: 'sections-data-structure',
        },
      },
      priceBook: {
        title: 'Price Book',
        fullPath: '/company-settings/:company/price-book',
        pattern: {
          path: 'price-book',
        },
      },
    },
  },
  compare: {
    title: 'Compare',
    fullPath: '/company/:company/project/:project?/compare',
    pattern: {
      path: '/company/:company/project/:project?/compare',
    },
  },
  campaigns: {
    title: 'Campaigns',
    fullPath: '/company/:company/project/:project?/campaigns',
    pattern: {
      path: '/company/:company/project/:project?/campaigns',
    },
  },
  notFound: {
    title: 'Not Found',
    fullPath: '*',
    pattern: {
      path: '*',
    },
  },
});
