import { costTrackerFields } from '~src/enums/cost-tracker';
import { CostType, TimeType } from '~src/enums/cost-setup';
import { round } from '@oliasoft-open-source/units';

const sum = (itemList, propName) =>
  itemList.reduce((sum, item) => sum + Number(item[propName]) || 0, 0) || null;

export const getSetionData = (section) => ({
  ...section,
  timeAfe: sum(section.operations, costTrackerFields.TIME_AFE),
  costAfe: sum(section.operations, costTrackerFields.COST_AFE),
  timeActual: sum(section.operations, costTrackerFields.TIME_ACTUAL),
  costActual: sum(section.operations, costTrackerFields.COST_ACTUAL),
  variance: sum(section.operations, costTrackerFields.VARIANCE),
});

export const thousandsSeparators = (input = '') => {
  const stringInput = input ? input.toString() : '';
  const formatted = stringInput.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  return formatted;
};

const convertCostAndCalculateSum = (costsArray) =>
  costsArray.reduce((acc, costItem) => {
    const operationsCount = costItem.operations.length;
    const calculationCondition =
      costItem.type &&
      costItem.type !== CostType.DayRate &&
      costItem.isDistributed &&
      operationsCount &&
      operationsCount !== 0;
    return (
      acc +
      (calculationCondition ? costItem.cost / operationsCount : costItem.cost)
    );
  }, 0);
const getRate = (item) => (item && item === TimeType.HOURS ? 24 : 1);

export const calculateExtraTime = (costsArray) =>
  costsArray.reduce((acc, costItem) => {
    if (costItem.extraTimeValue) {
      const { cost } = costItem;
      const rate = getRate(costItem.extraTimeUnit);
      return acc + cost * (costItem.extraTimeValue / rate);
    }
    return acc;
  }, 0);

export const getSuggestedValue = (costsArray, operationId, actualTime) => {
  const fixedCostsArray = costsArray
    .filter(
      (cost) =>
        cost.operations.includes(operationId) &&
        (cost.type === CostType.LumpSum || cost.type === CostType.Quantity),
    )
    .map((costItem) => {
      if (costItem.type === CostType.Quantity) {
        return {
          ...costItem,
          cost: costItem.unitCost * costItem[costItem.quantity],
        };
      } else return costItem;
    });
  const variableCostsArray = costsArray.filter(
    (cost) =>
      cost.operations.includes(operationId) && cost.type === CostType.DayRate,
  );
  const fixedCost = convertCostAndCalculateSum(fixedCostsArray);
  const extraCost = calculateExtraTime(variableCostsArray);
  const variableCost = convertCostAndCalculateSum(variableCostsArray) / 24;
  const suggestedCost = actualTime
    ? round(fixedCost + extraCost + variableCost * actualTime, 0)
    : null;
  return suggestedCost;
};
